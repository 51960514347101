<template>
	<div>
		<ul>
			<li
				v-for="(item, index) in items"
				:key="'item_' + index"
				@click="toDetail(item)"
			>
				<div
					style="width: 100%"
				>
					<img
						:src="item.cartl_chttg_img_url"
						@error="$bus.$emit('onErrorCartelProfileImage', $event)"
						class="width-100"
					/>
				</div>

				<div>
					{{ item.cartl_chttg_room_name }}
				</div>
			</li>
		</ul>
	</div>
</template>

<script>
	export default {
		name: 'LiveList'
		, props: ['user']
		, data: function(){
			return {

				program: {
					name: 'live list'
					, title: '라이브 목록'
					, type: 'cartel'
					, not_header: true
				}
				, item_search: {
					page_number: 1
					, pagerecnum: 10
				}
				, items: []
			}
		}
		, computed: {

		}
		, methods: {
			getData: async function(){
				try{
					//this.$bus.$emit('on', true)

					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.get_cartel_live_list
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
							, page_number: this.item_search.page_number
							, pagerecnum: this.item_search.pagerecnum
						}
						, type: true
					})

					if(result.success){
						this.items = result.data.cartl_chttg_room_list
						this.$storage.setQuery(this.item_search)
					}else{
						throw result.message
					}
				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}
			, toDetail: function(item){
				this.$bus.$emit('toLive', { name: 'CartelLiveDetail', params: { idx: this.$route.params.idx, live_idx: item.cartl_chttg_room_number}})
			}
		}
		, created() {
			this.$bus.$emit('onLoad', this.program)
			this.getData()
		}
	}
</script>